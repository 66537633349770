export const bool = ( val ) => !!val;
export const isArray = ( val ) => Array.isArray( val );
export const isBoolean = ( val ) => typeof val === 'boolean';
export const isUndefined = ( val ) => val === undefined;
export const isNull = ( val ) => val === null;
export const isNullish = ( val ) => val === null || val === undefined;
export const isSet = ( val ) => val !== null && val !== undefined;
export const isString = ( val ) => typeof val === 'string';
export const isInfinity = ( val ) => val === Infinity ? 1 : val === -Infinity ? -1 : 0;
export const isNumber = ( val ) => typeof val === 'number' && !isNaN( val ) && !isInfinity( val );
export const isNumeric = ( val ) => /^[-+]?\d+(\.\d+)?$/.test( val );
export const isInt = ( val ) => isNumber( val ) && val % 1 === 0;
export const isFloat = ( val ) => isNumber( val ) && val % 1 !== 0;
export const isObject = ( val ) => typeof val === 'object' && !isNull( val );
export const isFunction = ( val ) => typeof val === 'function';
export const isNode = ( val ) => val instanceof Node;
export const isElement = ( val ) => val instanceof Element;
export const isBlob = ( val ) => val instanceof Blob;
export const isPromise = ( val ) => val instanceof Promise;
export const isIterable = ( val ) => isObject( val ) && val[Symbol.iterator];
