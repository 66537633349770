import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {tryCatch} from '../helpers/tryCatch';

const { REACT_APP_API_URL } = process.env;

export const getEntityHash = ( id ) => tryCatch( () => id.split( '/' ).pop() );

export const createEntityTypeId = ( entity ) => `${ entity.__typename }:${ entity.id }`;

const httpLink = createHttpLink( {
  uri: `${ REACT_APP_API_URL }/graphql`,
} );

const authLink = setContext( ( _, { headers } ) => {
  const token = localStorage.getItem( 'authToken' );

  return {
    headers: {
      ...headers,
      'Authorization': token ? `Bearer ${ token }` : '',
			// 'Accept': 'application/json,application/bson',
    }
  }
} );

let cache = new InMemoryCache( {
  typePolicies: {
    default: {
      fields: {
        '*': {
          merge: ( existing = {}, incoming = {} ) => ( { ...existing, ...incoming } ),
        }
      }
    }
  }
} );

export const apolloAuthClient = new ApolloClient({
  link: authLink.concat( httpLink ),
  cache,
} );

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
} );
