// Возвращает базовое имя файла (с опциональным удалением суффикса)
export const basename = ( filePath, suffix = '' ) => {
  const base = filePath.split('/').pop(); // Убираем путь, оставляем только имя файла
  return suffix && base.endsWith(suffix) ? base.slice(0, -suffix.length) : base;
};

// Возвращает имя директории
export const dirname = ( filePath ) => {
  const parts = filePath.split('/');
  parts.pop(); // Убираем последнее имя файла или папки
  return parts.join('/') || '.';
};

// Возвращает расширение файла
export const extname = ( filePath ) => {
  const match = filePath.match(/\.([^.]+)$/);
  return match ? `.${match[1]}` : '';
};

// Форматирует объект пути в строку
export const format = ( pathObject ) => {
  const { dir, root, base, name, ext } = pathObject;
  if (!base && name) {
    pathObject.base = `${name}${ext || ''}`;
  }
  return `${dir || root || ''}${dir && root ? '' : '/'}${pathObject.base || ''}`;
};

// Объединяет пути, обрабатывая `.` и `..`
export const join = ( ...paths ) => {
  return paths
    .filter(Boolean)
    .join('/')
    .replace(/\/+/g, '/')
    .replace(/\/\.\//g, '/')
    .replace(/\/[^/]+\/\.\.\//g, '/');
};

// Нормализует путь, убирая лишние слэши и обрабатывая `.` и `..`
export const normalize = ( filePath ) => {
  return filePath
    .replace(/\/+/g, '/')
    .replace(/\/\.\//g, '/')
    .replace(/\/[^/]+\/\.\.\//g, '/')
    .replace(/\/$/, ''); // Убираем завершающий слэш, если это не корень
};

// Разбирает путь в объект
export const parse = ( filePath ) => {
  const ext = this.extname(filePath);
  const base = this.basename(filePath);
  const dir = this.dirname(filePath);
  return {
    root: filePath.startsWith('/') ? '/' : '',
    dir,
    base,
    ext,
    name: base.replace(ext, ''),
  };
};

export default {
  basename,
  dirname,
  extname,
  format,
  join,
  normalize,
  parse,
};

